<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form size="small" ref="inputForm" :model="inputForm" :rules="rules" label-width="100px">
            <el-form-item label="参数名称" prop="configName">
                <el-input v-model.trim="inputForm.configName" clearable maxlength="50"
                          placeholder="请输入参数名称(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="参数键名" prop="configKey">
                <el-input v-model.trim="inputForm.configKey" clearable maxlength="50"
                          placeholder="请输入参数键名(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="参数键值" prop="configValue">
                <el-input v-model.trim="inputForm.configValue" clearable maxlength="50"
                          placeholder="请输入参数键值(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="系统内置" prop="configType">
                <el-radio-group v-model="inputForm.configType">
                    <el-radio :label="'Y'">是</el-radio>
                    <el-radio :label="'N'">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                        v-model="inputForm.remark"
                        clearable
                        type="textarea"
                        :rows="3"
                        maxlength="200"
                        style="width: 100%"
                        placeholder="请输入备注(限200字)">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "addParameter",
    data() {
        return {
            title: '添加参数',
            dialogVisible: false,
            inputForm: {
                configName: '',
                configKey: '',
                configValue: '',
                configType: 'Y',
                remark: '',
            },
            rules: {
                configName: [
                    {required: true, message: '请输入参数名称', trigger: 'blur'}
                ],
                configKey: [
                    {required: true, message: '请输入参数键名', trigger: 'blur'}
                ],
                configValue: [
                    {required: true, message: '请输入参数键值', trigger: 'blur'}
                ],
            },
        }
    },

    methods: {
        //num 0新增 2修改
        init(num, row) {
            if (num != 0) {
                this.$axios(this.api.auth.systemConfig + '/' + row.configId, {}, 'get').then((res) => {
                    if (res.status) {
                        this.dialogVisible = true
                        this.$nextTick(() => {
                            this.inputForm = res.data
                        })
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                this.dialogVisible = true
            }
        },

        sureData() {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    let methodsE = 'post'
                    if (this.inputForm.configId){
                        methodsE = 'put'
                    }
                    this.$axios(this.api.auth.systemConfig, {
                        ...this.inputForm,
                    }, methodsE).then((res) => {
                        if (res.status) {
                            this.$message.success('保存成功')
                            this.$emit('refresh')
                            this.handleClose()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.inputForm.configId = null
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>
